<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <action-icon
      :action-type="action.type"
      class="action-icon"
      :class="`action-style-${action.type.toLowerCase()}`"
    />
    <div class="action-name">{{ action.name }}</div>
    <component
      :is="actionStat(action)"
      class="action-stats"
      :stats="action.stats"
      @click.native="onStatsClick"
    />
    <div class="action-options">
      <template v-for="key in Object.keys(action.successors)">
        <div
          :style="{ width: `calc(100%/${getSuccessorCount()})` }"
          class="action-option-multidecision-stat"
        >
          <div v-tooltip:left="$t('actionTooltipArrived')" class="glyphicon glyphicon-user" />
          <div>
            {{ formatStats(action.stats['MultiDecision:' + key]) }}
          </div>
        </div>
      </template>
    </div>
    <div class="action-options">
      <template v-for="key in Object.keys(action.successors)">
        <div
          v-tooltip="action.config.successorTitles[key]"
          :style="{ width: `calc(100%/${getSuccessorCount()})` }"
          class="action-option-multidecision"
        >
          {{ formatKey(key) }}
        </div>
      </template>
    </div>
    <div v-if="!workflow.isSnapshot" class="action-overlay">
      <icon
        v-if="!readOnly"
        v-tooltip="$t('actionControlsDelete')"
        class="action-delete"
        glyph="trash"
        @click="deleteAction"
      />
      <icon
        v-if="!readOnly"
        v-tooltip="$t('actionControlsCopy')"
        class="action-copy"
        glyph="copy"
        @click="copyAction"
      />
      <icon
        v-tooltip="$t('actionControlsReporting')"
        class="action-reporting"
        :class="{ 'icon-hidden': !singleSelect }"
        glyph="bar-chart"
        @click="showReporting"
      />
    </div>
  </div>
</template>
<script>
import Action from './AbstractAction'
import { ANCHORS } from '@/views/WorkflowEdit/Actions/MultiDecisionActionAnchors'
import { SUCCESSOR_OFFSET } from '@/views/WorkflowEdit/Actions/MultiDecisionActionSuccessorOffset'

export default {
  extends: Action,

  inject: ['eventBus'],

  data() {
    return {
      icon: 'question-mark'
    }
  },

  watch: {
    'action.successors': {
      handler() {
        if (
          this.getSuccessorCount() !==
          this.jsPlumb.getEndpoints(this.$el).length - 1
        ) {
          this.updateEndpoints()
          this.redrawAnchors()
        }
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    }
  },

  created() {
    this.$options.successorAnchors = this.getAnchors()
    this.$options.successorOffset = this.getSuccessorOffset()
    this.formatter = Intl.NumberFormat('en', { notation: 'compact' })
    this.eventBus.$on('multidecision-endpoint-update', (id) =>
      this.updateAfterGroupMove(id)
    )
  },

  methods: {
    formatKey(key) {
      if (key === 'else') {
        return this.$t('multiDecisionElse')
      }

      return key
    },
    updateAfterGroupMove(id) {
      if (id === this.action.id) {
        this.updateEndpoints()
        this.redrawAnchors()
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    },
    onConnection(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName'),
        targetId = info.targetEndpoint.getParameter('actionId')
      if (
        this.action.id === sourceId &&
        targetId &&
        this.action.successors[successorName] !== targetId
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = targetId
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    onConnectionDetached(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName')
      if (
        this.action.id === sourceId &&
        this.action.successors.hasOwnProperty(successorName) &&
        this.action.successors[successorName] !== ''
      ) {
        const successors = Object.assign({}, this.action.successors)
        successors[successorName] = ''
        this.workflowApi.updateAction(sourceId, { successors: successors })
      }
    },

    updateEndpoints() {
      const successorCount = this.getSuccessorCount()
      let successorsBackup = {}

      // backup the successors
      for (const [successorKey, value] of Object.entries(
        this.action.successors
      )) {
        successorsBackup[successorKey] = value
      }

      const sessionStorageKey = this.action.id + '-successors'
      if (!sessionStorage.getItem(sessionStorageKey)) {
        sessionStorage.setItem(
          sessionStorageKey,
          JSON.stringify(successorsBackup)
        )
      } else {
        successorsBackup = JSON.parse(sessionStorage.getItem(sessionStorageKey))
      }

      // delete the endpoints
      const endpoints = this.jsPlumb.getEndpoints(this.$el)
      for (let i = 1; i <= successorCount + 1; i++) {
        this.jsPlumb.deleteEndpoint(endpoints[i], true)
      }

      // re-add the endpoints
      for (const [successorKey, value] of Object.entries(successorsBackup)) {
        this.renderOutput(successorKey)
        this.action.successors[successorKey] = value
      }
    },

    redrawAnchors() {
      const anchors = this.getAnchors()
      const anchorKeys = Object.keys(anchors)
      this.$options.successorAnchors = anchors
      this.$options.successorOffset = this.getSuccessorOffset()

      // reposition existing anchors from index 1
      this.jsPlumb.getEndpoints(this.$el).forEach((endpoint, index) => {
        if (index === 0) {
          return
        }

        endpoint.setAnchor(anchors[anchorKeys[index - 1]])
      })

      this.$nextTick(() => this.jsPlumb.repaintEverything())
    },

    getAnchors() {
      return ANCHORS[this.getSuccessorCount()]
    },

    getSuccessorOffset() {
      return SUCCESSOR_OFFSET[this.getSuccessorCount()]
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    },

    formatStats(stat) {
      if (!stat) {
        return 0
      }

      return this.formatter.format(stat)
    }
  }
}
</script>
<style scoped lang="sass">
.action
  height: 165px

.action-option-multidecision
  color: #ffffff
  background-color: #f6b352
  border-top: 2px solid #fff
  border-right: 2px solid #fff
  box-sizing: border-box
  float: left
  padding: 12px 0
  text-transform: uppercase
  text-align: center

.action-option-multidecision-stat
    color: #707070
    background-color: #c3c3c3
    border-top: 2px solid #fff
    border-right: 2px solid #fff
    box-sizing: border-box
    float: left
    padding: 6px 0 5px 0
    text-transform: uppercase
    text-align: center

.action-options div:last-child
  border-right: none

.action-overlay
  border-radius: 0 5px 0 0
</style>
