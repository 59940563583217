<template>
  <div class="phone">
    <div class="speaker" />
    <div class="container">
      <div class="background">
        <div class="time">
          {{ time }}
        </div>
        <div class="date">
          {{ date }}
        </div>
        <slot />
      </div>
    </div>
    <div class="lock-button" />
  </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/pt'

export default {
  data() {
    return {
      currentDate: new Date()
    }
  },

  computed: {
    time() {
      return moment(this.currentDate).format('hh:mm')
    },

    date() {
      return moment(this.currentDate)
        .locale(this.$store.getters.getLanguage)
        .format('dddd DD MMMM')
    }
  },

  created() {
    this.$timeInterval = setInterval(() => {
      this.currentDate = new Date()
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.$timeInterval)
  }
}
</script>
<style lang="sass" scoped>
.phone
    border: 2px solid #767676
    border-radius: 40px
    /*display: inline-block*/
    width: 355px
    height: 590px

    .speaker
        width: 60px
        height: 10px
        margin: 20px auto
        border: 2px solid #767676
        border-radius: 6px
        background-color: #bcbcbb

    .container
        width: 320px
        height: 480px
        border: 2px solid #767676
        margin: 0 15px
        padding: 0

        .background
            height: 100%
            width: 100%
            background-color: #083146
            font-family: Helvetica Neue, sans-serif
            padding-top: 50px
            overflow-x: hidden
            overflow-y: auto

            .time
                text-align: center
                font-size: 60px
                font-weight: 200
                color: #fff

            .date
                text-align: center
                font-size: 20px
                font-weight: 100
                color: #fff
                margin: 10px 0

    .lock-button
        height: 36px
        width: 36px
        margin: 10px auto
        border: 2px solid #767676
        border-radius: 18px
</style>
