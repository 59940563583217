<template>
  <div
    class="action"
    :class="{
      'action-selected': selected,
      'multi-select-cursor-allowed': shiftHover && !readOnly,
      'multi-select-cursor-not-allowed': shiftHover && readOnly
    }"
    :style="actionStyle"
    :data-action-id="action.id"
    @click="onClick"
    @mousedown="onMouseDown"
    @mouseover="onMouseOver"
    @mousemove="onMouseOver"
    @mouseleave="onMouseLeave"
  >
    <action-icon
      :action-type="workflowApi.getActionType(action)"
      class="action-icon"
      :class="`action-style-${action.type.toLowerCase()}`"
    ></action-icon>
    <div class="action-name">{{ translateStartAction(action) }}</div>
    <component
      :is="actionStat(action)"
      class="action-stats"
      :stats="action.stats"
      v-bind="actionStatExtendedProps(action)"
      @click.native="onStatsClick"
    />
    <div v-if="!workflow.isSnapshot" class="action-overlay">
      <icon
        v-if="!readOnly"
        v-tooltip="$t('actionControlsDelete')"
        class="action-delete"
        glyph="trash"
        @click="deleteAction"
      />
      <icon
        v-if="!readOnly"
        v-tooltip="$t('actionControlsCopy')"
        class="action-copy"
        glyph="copy"
        @click="copyAction"
      />
      <icon
        v-tooltip="$t('actionControlsReporting')"
        class="action-reporting"
        :class="{ 'icon-hidden': !singleSelect }"
        glyph="bar-chart"
        @click="showReporting"
      />
    </div>
  </div>
</template>
<script>
import { CELL_SIZE, OFFSET_LEFT, OFFSET_TOP, WIDTH, HEIGHT } from '../GridSize'

import '@/svg/add.svg'

import ActionIcon from '@/components/ActionIcon'

export default {
  components: {
    ActionIcon: ActionIcon
  },

  inject: ['workflowApi', 'jsPlumb', 'eventBus', 'zIndexCounter'],
  props: ['workflow', 'action', 'selectedActions', 'readOnly'],

  successorAnchors: {
    primary: 'BottomCenter'
  },

  successorOffset: {
    primary: {
      left: 0,
      top: CELL_SIZE * 3
    }
  },

  data() {
    return {
      top: 0,
      left: 0,
      shiftHover: false
    }
  },

  computed: {
    actionStyle() {
      this.$nextTick(() => this.jsPlumb.revalidate(this.$el))

      const pos = this.action.pos.split(',')
      let left = parseInt(pos[0]) + OFFSET_LEFT,
        top = parseInt(pos[1]) + OFFSET_TOP

      if (left < 0) left = 0
      if (top < 0) top = 0
      if (left > WIDTH) left = WIDTH - CELL_SIZE
      if (top > HEIGHT) top = HEIGHT - CELL_SIZE

      return {
        left: `${left}px`,
        top: `${top}px`
      }
    },

    descendantIds() {
      return this.getChildren(this.action.id)
    },

    selected() {
      return this.selectedActions.isSelected(this.action.id)
    },

    singleSelect() {
      return this.selectedActions.count === 1
    }
  },

  watch: {
    'action.successors': {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.drawLines()
        })
      }
    },
    selected(selected) {
      if (selected) {
        this.arrangeFront()
      }
    }
  },

  created() {
    this.actionOutputs = {}
    this.eventBus.$on('connectionDrag', this.onConnectionDrag)
    this.eventBus.$on('connectionDragStop', this.onConnectionDragStop)
    this.eventBus.$on('connection', this.onConnection)
    this.eventBus.$on('connectionDetached', this.onConnectionDetached)
    this.eventBus.$on('moveAction', this.moveAction)
  },

  mounted() {
    this.initDraggable()
    this.renderInputs()
    this.renderOutputs()
    this.$nextTick(() => {
      this.drawLines()
      this.arrangeFront()
    })
  },

  beforeDestroy() {
    this.eventBus.$off('connectionDrag', this.onConnectionDrag)
    this.eventBus.$off('connectionDragStop', this.onConnectionDragStop)
    this.eventBus.$off('connection', this.onConnection)
    this.eventBus.$off('connectionDetached', this.onConnectionDetached)
    this.eventBus.$off('moveAction')
    this.jsPlumb.removeAllEndpoints(this.$el)
  },

  methods: {
    translateStartAction(action) {
      if (action.type === 'Start' && action.name === 'Start') {
        return this.$t('actionStart')
      }

      return action.name
    },

    actionStat(action) {
      if (this.workflow.isSnapshot) {
        return require(`./Stats/SnapshotStat`).default
      }
      return require(`./Stats/${action.type}Stat`).default
    },

    actionStatExtendedProps(action) {
      switch (action.type) {
        case 'Email':
          return {
            'track-email-open':
              this.workflow.features.email_open_tracking != undefined
                ? this.workflow.features.email_open_tracking
                : true,
            'track-email-click':
              this.workflow.features.email_click_tracking != undefined
                ? this.workflow.features.email_click_tracking
                : true
          }
        default:
          return {}
      }
    },

    deleteAction(e) {
      e.stopPropagation()
      this.selectedActions.delete()
    },

    moveAction(payload) {
      if (payload.actionId !== this.action.id) {
        return
      }

      if (payload.update) {
        this.action.pos = payload.startPos.join(',')
        this.workflowApi.updateAction(payload.actionId, { pos: payload.newPos })
        return
      }

      this.action.pos = payload.newPos
    },

    copyAction(e) {
      e.stopPropagation()
      this.selectedActions.copy()
    },

    showReporting(e) {
      e.stopPropagation()
      this.eventBus.$emit('showActionReporting', true)
    },

    onMouseDown(e) {
      this.mouseDownPos = {
        pageX: e.pageX,
        pageY: e.pageY
      }
    },

    onMouseOver(e) {
      this.shiftHover = e.shiftKey
    },

    onMouseLeave() {
      this.shiftHover = false
    },

    onClick(e) {
      if (
        !(
          this.mouseDownPos.pageX === e.pageX &&
          this.mouseDownPos.pageY === e.pageY
        )
      ) {
        return
      }

      if (!e.shiftKey) {
        this.eventBus.$emit('onShift', false)
        this.selectedActions.selectAction(this.action.id)
      } else if (!this.readOnly) {
        this.eventBus.$emit('onShift', true)
        this.selectedActions.toggleSelect(this.action.id)
      }
    },

    onStatsClick(e) {
      if (
        !(
          this.mouseDownPos.pageX === e.pageX &&
          this.mouseDownPos.pageY === e.pageY
        )
      ) {
        return
      }

      e.stopPropagation()
      this.eventBus.$emit('selectAction', this.action.id, {
        showActionReporting: true
      })
    },

    arrangeFront() {
      const zIndex = this.zIndexCounter.increment()
      this.$el.style.zIndex = zIndex
      if (this.actionInput) {
        this.actionInput.canvas.style.zIndex = zIndex
      }

      Object.keys(this.actionOutputs).forEach((outputName) => {
        this.actionOutputs[outputName].canvas.style.zIndex = zIndex
      })
    },

    onConnection(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName'),
        targetId = info.targetEndpoint.getParameter('actionId')
      if (
        this.action.id === sourceId &&
        targetId &&
        this.action.successors[successorName] !== targetId
      ) {
        this.workflowApi.updateAction(sourceId, {
          successors: {
            [successorName]: targetId
          }
        })
      }
    },

    onConnectionDetached(info) {
      const sourceId = info.sourceEndpoint.getParameter('actionId'),
        successorName = info.sourceEndpoint.getParameter('successorName')
      if (
        this.action.id === sourceId &&
        this.action.successors[successorName] !== ''
      ) {
        this.workflowApi.updateAction(sourceId, {
          successors: {
            [successorName]: ''
          }
        })
      }
    },

    getChildren(actionId, children) {
      const action = this.workflow.actions.find(
        (action) => action.id === actionId
      )

      children = children || []
      let successors = (action && Object.values(action.successors)) || []

      successors.forEach((child) => {
        if (children.includes(child) || child === '') {
          return
        }
        children.push(child)
        this.getChildren(child, children)
      })

      return children
    },

    isAncestorOf: function (actionId) {
      return (
        actionId === this.action.id || this.descendantIds.includes(actionId)
      )
    },

    onConnectionDrag(connection) {
      const sourceId = connection.getParameter('actionId')

      if (this.isAncestorOf(sourceId)) {
        this.$el.classList.add('action-nodrop')
        this.actionInput.setEnabled(false)
        this.actionInput.addClass('action-input-nodrop')
      } else {
        this.$el.classList.add('action-allow-drop')
        this.actionInput.setEnabled(true)
        this.actionInput.addClass('action-input-allow-drop')
      }
    },

    onConnectionDragStop() {
      this.$el.classList.remove('action-nodrop')
      this.$el.classList.remove('action-allow-drop')
      this.actionInput.setEnabled(false)
      this.actionInput.removeClass('action-input-nodrop')
      this.actionInput.removeClass('action-input-allow-drop')
    },

    renderInputs() {
      this.actionInput = this.jsPlumb.addEndpoint(this.$el, {
        uuid: `${this.action.id}:input`,
        isTarget: true,
        detachable: true,
        anchor: 'TopCenter',
        maxConnections: 100,

        cssClass: 'action-input',

        endpoint: [
          'Dot',
          {
            radius: 10
          }
        ],

        parameters: {
          actionId: this.action.id
        }
      })
    },

    renderOutputs() {
      Object.keys(this.action.successors).forEach((successorName) =>
        this.renderOutput(successorName)
      )
    },

    renderOutput(successorName) {
      const endpoint = this.jsPlumb.addEndpoint(this.$el, {
        uuid: `${this.action.id}:${successorName}`,
        isSource: true,
        anchor: this.$options.successorAnchors[successorName],
        maxConnections: 100,

        endpoint: [
          'Rectangle',
          {
            cssClass: `action-output action-output-${successorName}`,
            width: CELL_SIZE,
            height: CELL_SIZE
          }
        ],

        connector: [
          'Flowchart',
          {
            stub: CELL_SIZE,
            alwaysRespectStubs: true,
            cornerRadius: CELL_SIZE / 2
          }
        ],

        connectorStyle: {
          strokeWidth: 2,
          stroke: '#707070'
        },

        parameters: {
          actionId: this.action.id,
          successorName: successorName
        }
      })

      endpoint.bind('dblclick', this.onEndpointDblClick)

      const div = document.createElement('div')
      div.classList.add('action-output-add-action')
      div.innerHTML = `<svg style="pointer-events: none"><use xlink:href="#add"></use></svg>`
      div.addEventListener('click', () =>
        this.eventBus.$emit('showAddAction', this, successorName)
      )

      endpoint.canvas.appendChild(div)

      this.actionOutputs[successorName] = endpoint
    },

    drawLines() {
      Object.keys(this.action.successors).forEach((successorName) => {
        const endpoint = this.actionOutputs[successorName]

        if (!this.action.successors[successorName]) {
          endpoint.connections.forEach((connection) => {
            if (connection.pending) {
              return
            }
            this.jsPlumb.deleteConnection(connection)
          })
          return
        }

        this.jsPlumb.connect(
          {
            uuids: [
              `${this.action.id}:${successorName}`,
              `${this.action.successors[successorName]}:input`
            ]
          },
          {
            cssClass: 'action-connector',
            hoverClass: 'action-connector-hover'
          }
        )
      })
    },

    initDraggable() {
      this.jsPlumb.draggable(this.$el, {
        consumeStartEvent: false,
        snapThreshold: CELL_SIZE,
        grid: [CELL_SIZE, CELL_SIZE],
        start: (e) => {
          if (this.readOnly) {
            e.drag.abort()
          }

          this.startRect = this.$el.getBoundingClientRect()

          this.arrangeFront()

          this.selectedActions.startMove(
            this.action.pos.split(',').map((_) => parseInt(_))
          )
        },
        drag: (e) => {
          if (this.startRect) {
            const rect = this.$el.getBoundingClientRect()
            if (
              this.startRect.left !== rect.left ||
              this.startRect.top !== rect.top
            ) {
              this.startRect = null
            }
          }

          this.selectedActions.move(this.action.id, [
            parseInt(e.pos[0]) - OFFSET_LEFT,
            parseInt(e.pos[1]) - OFFSET_TOP
          ])
        },
        stop: (e) => {
          const pos = [
            parseInt(e.pos[0]) - OFFSET_LEFT,
            parseInt(e.pos[1]) - OFFSET_TOP
          ].join(',')

          if (this.action.pos !== pos) {
            this.workflowApi.updateAction(this.action.id, { pos })
          }

          this.selectedActions.move(
            this.action.id,
            pos.split(',').map((_) => parseInt(_)),
            true
          )
          this.selectedActions.endMove()
        }
      })
    },

    onEndpointDblClick(endpoint) {
      this.eventBus.$emit('clearSelection')
      endpoint.connections.forEach((connection) => {
        if (connection.isDetachable()) {
          this.jsPlumb.deleteConnection(connection)
        }
      })

      return false
    }
  }
}
</script>
<style lang="sass">
.action
    position: absolute
    border-radius: 5px
    border: 1px solid #b4b4b4
    overflow: hidden
    height: 66px
    cursor: pointer

    &:after
        transition: opacity .3s ease
        pointer-events: none
        opacity: 0
        content: ''
        position: absolute
        background-color: #ddd
        top: 0
        left: 0
        bottom: 0
        right: 0

    .action-icon
        float: left
        color: #fff
        width: 64px
        height: 64px
        padding: 17px

    .icon-hidden
        pointer-events: none
        visibility: hidden

    .action-name
        float: left
        width: 154px
        height: 64px
        padding: 5px
        font-size: 14px
        line-height: 18px
        word-wrap: break-word
        background-color: #F0F1F4
        display: flex
        align-items: center

    .action-stats
        float: left
        width: 88px
        height: 64px
        padding: 0
        margin: 0
        list-style-type: none
        background-color: #c3c3c3
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: center
        li .icon
            margin: 0 5px

    .action-overlay
        transition: transform 0.3s linear
        transform: translateX(242px)
        position: absolute
        display: flex
        justify-content: flex-end
        height: 64px
        top: 0
        left: 64px
        right: 0
        color: #fff
        background-color: $gray
        .icon
            margin-right: 12px
            padding: 17px
            width: 64px
            height: 64px

    &.action-nodrop
        &:after
            opacity: .5

    &.action-selected
        box-shadow: 3px 3px 5px #9b9b9b
        .action-overlay
            transform: translateX(0)

    &.multi-select-cursor-allowed
        cursor: copy

    &.multi-select-cursor-not-allowed
        cursor: not-allowed

.workflow.workflow-has-selection
    .action:after
        opacity: .5

    .action.action-selected:after
        opacity: 0

.action-output
    z-index: 1
    margin-top: 11px
    cursor: move

    svg
        border-radius: 0 0 5px 5px
        rect
            fill: $gray

    &.jtk-hover
        z-index: 99992 !important

    &.jtk-dragging svg
        border-radius: 22px

    &.jtk-drag-active
        opacity: 0

    &.jtk-endpoint-connected
        .action-output-add-action
            display: none

    .action-output-add-action
        z-index: 1
        position: absolute
        width: 22px
        height: 22px
        svg
            padding: 5px
            width: 22px
            height: 22px
            color: #fff

.action-connector-hover
    filter: drop-shadow(2px 2px 3px #9b9b9b)
    z-index: 99991
    path
        stroke-width: 4px

.action-input
    opacity: 0
    z-index: 1
    transition: opacity .3s ease
    svg circle
        fill: #00AB66

    &.action-input-allow-drop
        opacity: 1

    &.jtk-endpoint-drop-allowed
        svg circle
            fill: lighten(#00AB66, 10%)

.jtk-dragging.jtk-connector,
.jtk-dragging.jtk-endpoint
    z-index: 999

.jtk-drag-select,
.action.jtk-drag
    cursor: move
</style>
